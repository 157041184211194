import { AxiosInstance } from 'axios';
import { differenceInMinutes, formatDuration, intervalToDuration, isSameDay } from 'date-fns';
import { t } from 'i18next';

import { Attempt, AttemptStatus, DeliveryCancelReason } from '@coco/types/deliveries';

import { DELIVERY_STATUS_KEYS, LOC_NS, ORDERS_KEYS } from 'src/i18n/types';
import { getPII } from 'src/service';
import { AttemptProvider, DeliveryOperationalMode, ERROR, ListDelivery, Status, StatusType, Trip } from '../@types';
import { getDoorDashStatus } from './delivery-doordash';
import { getRobotStatus } from './delivery-robot';
import { calculateTimeCopy, deliveryStatusT } from './delivery-shared';
import { logError } from './logging';
import { getDateFnsLocale, localeFormatDate } from './utils';

export const ordersT = (key: ORDERS_KEYS, fallback: string) => {
  return t(`${LOC_NS.ORDERS}:${key}`, fallback);
};

const getCourierStatus = (
  currentAttemptStatus: ListDelivery['currentAttemptStatus'],
  statusTimestampsMap: ListDelivery['statusTimestampsMap'],
  operationalMode: ListDelivery['operationalMode'],
  updatedAt: string
): Status => {
  if (
    statusTimestampsMap?.Requested &&
    (currentAttemptStatus === AttemptStatus.Pending ||
      currentAttemptStatus === AttemptStatus.Requested ||
      currentAttemptStatus === AttemptStatus.Scheduled)
  ) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.WAITING_FOR_DRIVER, 'Waiting for driver'),
      timeCopy: `${calculateTimeCopy(new Date(statusTimestampsMap.Requested))}`,
      statusType: StatusType.WAITING_FOR_LOAD,
    };
  }

  if (
    statusTimestampsMap?.Assigned &&
    (currentAttemptStatus === AttemptStatus.Assigned || currentAttemptStatus === AttemptStatus.InTransitToRescue)
  ) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.ON_THE_WAY_TO_PICKUP, 'On the way to pickup'),
      timeCopy: `${calculateTimeCopy(new Date(statusTimestampsMap?.Assigned))}`,
      statusType: StatusType.WAITING_FOR_LOAD,
    };
  }

  if (
    statusTimestampsMap?.InTransitToRescue &&
    !!currentAttemptStatus &&
    ([AttemptStatus.InTransitToRescue, AttemptStatus.AtBot] as AttemptStatus[]).includes(currentAttemptStatus)
  ) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.SUPPORT_HEADING_TO_BOT, 'Support heading to bot'),
      timeCopy: `${calculateTimeCopy(new Date(statusTimestampsMap.InTransitToRescue))}`,
      statusType: StatusType.IN_TRANSIT,
    };
  }

  if (statusTimestampsMap?.AtPickup && currentAttemptStatus === AttemptStatus.AtPickup) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.DRIVER_WAITING, 'Driver waiting'),
      timeCopy: `${calculateTimeCopy(new Date(statusTimestampsMap.AtPickup))}`,
      statusType: StatusType.WAITING_FOR_LOAD,
    };
  }

  if (currentAttemptStatus === AttemptStatus.InTransit) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.ON_THE_WAY_TO_CUSTOMER, 'On the way to customer'),
      timeCopy: '',
      statusType: StatusType.IN_TRANSIT,
    };
  }

  if (statusTimestampsMap?.AtDestination && currentAttemptStatus === AttemptStatus.AtDestination) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.AT_CUSTOMER, 'At customer'),
      timeCopy: `${calculateTimeCopy(new Date(statusTimestampsMap.AtDestination))}`,
      statusType: StatusType.AT_CUSTOMER,
    };
  }

  if (statusTimestampsMap?.AtDestination && currentAttemptStatus === AttemptStatus.Delivered) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.DELIVERED, 'Delivered'),
      timeCopy: `${calculateTimeCopy(new Date(statusTimestampsMap.AtDestination))}`,
      statusType: StatusType.DELIVERED,
    };
  }

  /* Order canceled */
  if (statusTimestampsMap?.Canceled && currentAttemptStatus === AttemptStatus.Canceled) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.CANCELED, 'Canceled'),
      timeCopy: `${calculateTimeCopy(new Date(statusTimestampsMap.Canceled))}`,
      statusType: StatusType.CANCELED,
    };
  }

  /* Order canceled through 3rd party */
  if (operationalMode === DeliveryOperationalMode.Canceled && updatedAt) {
    return {
      statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.CANCELED, 'Canceled'),
      timeCopy: `${calculateTimeCopy(new Date(updatedAt))}`,
      statusType: StatusType.CANCELED,
    };
  }

  return {
    statusCopy: `${currentAttemptStatus}`,
    timeCopy: '',
    statusType: StatusType.WAITING_FOR_LOAD,
  };
};

const getScheduledStatus = (scheduledFor: string): Status => {
  const scheduledForDate = new Date(scheduledFor);

  const scheduledDateIsToday = isSameDay(new Date(), scheduledForDate);

  return {
    statusCopy: deliveryStatusT(DELIVERY_STATUS_KEYS.SCHEDULED_FOR, 'Scheduled for'),
    timeCopy: localeFormatDate(new Date(scheduledFor), scheduledDateIsToday ? 'p' : 'PPp'),
    statusType: StatusType.SCHEDULED,
  };
};

export const getStatus = (delivery: ListDelivery, currentTrip?: Trip): Status => {
  const {
    currentAttemptStatus,
    statusTimestampsMap,
    providedBy,
    eta,
    targetDropoffTime,
    expectedLoadTimeMin,
    scheduledFor,
    deliveryMedium,
    operationalMode,
    updatedAt,
  } = delivery;

  if (!currentAttemptStatus && scheduledFor) {
    return getScheduledStatus(scheduledFor);
  }

  if (providedBy === AttemptProvider.ROBOT) {
    return getRobotStatus(
      currentAttemptStatus,
      statusTimestampsMap,
      eta,
      targetDropoffTime,
      expectedLoadTimeMin,
      operationalMode,
      updatedAt,
      currentTrip
    );
  }

  if (providedBy === AttemptProvider.DOOR_DASH) {
    return getDoorDashStatus(
      currentAttemptStatus,
      statusTimestampsMap,
      deliveryMedium,
      eta,
      operationalMode,
      updatedAt
    );
  }

  return getCourierStatus(currentAttemptStatus, statusTimestampsMap, operationalMode, updatedAt);
};

export const getRobotArrivesIn = (attempt: Attempt): string => {
  if (!attempt?.estPickupTime) {
    logError({ message: 'missing estPickupTime' }, ERROR.MISSING_ESTIMATED_PICKUP_TIME, attempt);
    return ordersT(ORDERS_KEYS.ARRIVING_SOON, 'Arriving soon');
  }

  if (new Date(attempt?.estPickupTime) < new Date()) {
    logError({ message: 'past estPickupTime' }, ERROR.PAST_ESTIMATED_PICKUP_TIME, attempt);
    return ordersT(ORDERS_KEYS.ARRIVING_NOW, 'Arriving now');
  }

  const duration = intervalToDuration({
    start: new Date(),
    end: new Date(attempt?.estPickupTime),
  });
  const formattedDuration = formatDuration(duration, { locale: getDateFnsLocale(), format: ['hours', 'minutes'] });
  return formattedDuration === ''
    ? ordersT(ORDERS_KEYS.ARRIVING_NOW, 'Arriving now')
    : `${ordersT(ORDERS_KEYS.ARRIVES_IN, 'Arrives in')} ${formattedDuration}`;
};

export const sortActiveDeliveries = (deliveries: ListDelivery[] = [], maxCanceledAgeMins = 10): ListDelivery[] => {
  const deliveriesLateLoaded: ListDelivery[] = [];
  const deliveriesScheduled: ListDelivery[] = [];
  const deliveriesAtCustomer: ListDelivery[] = [];
  const deliveriesInTransit: ListDelivery[] = [];
  const deliveriesWaitingForLoad: ListDelivery[] = [];
  const deliveriesCanceled: ListDelivery[] = [];

  deliveries.forEach((delivery: ListDelivery) => {
    if (
      delivery.operationalMode === DeliveryOperationalMode.Canceled ||
      delivery.currentAttemptStatus === AttemptStatus.Canceled
    ) {
      if (
        // only keep deliveries canceled within the past 10 minutes
        differenceInMinutes(new Date(), new Date(delivery.updatedAt)) <= maxCanceledAgeMins &&
        // merchants don't care about orders that have already been handed off or loaded
        !delivery?.statusTimestampsMap?.InTransit &&
        // merchants don't care about duplicate or test orders
        !(delivery?.cancelReason === DeliveryCancelReason.TestOrder) &&
        !(delivery?.cancelReason === DeliveryCancelReason.DuplicateOrder) &&
        !(delivery?.cancelReason === DeliveryCancelReason.MerchantRequestedDuplicateOrder)
      ) {
        deliveriesCanceled.push(delivery);
      }
    } else {
      const { statusType } = getStatus(delivery);
      switch (statusType) {
        case StatusType.LATE_LOADED:
          deliveriesLateLoaded.push(delivery);
          break;
        case StatusType.IN_TRANSIT:
          deliveriesInTransit.push(delivery);
          break;
        case StatusType.AT_CUSTOMER:
          deliveriesAtCustomer.push(delivery);
          break;
        case StatusType.SCHEDULED:
          deliveriesScheduled.push(delivery);
          break;
        default:
          deliveriesWaitingForLoad.push(delivery);
      }
    }
  });

  deliveriesLateLoaded.sort((deliveryA, deliveryB) => {
    // sort late deliveries in descending order
    return new Date(deliveryA.createdAt).valueOf() - new Date(deliveryB.createdAt).valueOf();
  });

  deliveriesScheduled.sort((deliveryA, deliveryB) => {
    // sort scheduled deliveries in ascending order
    return new Date(deliveryB.createdAt).valueOf() - new Date(deliveryA.createdAt).valueOf();
  });

  deliveriesWaitingForLoad.sort((deliveryA, deliveryB) => {
    // sort pending deliveries in descending order
    return new Date(deliveryA.createdAt).valueOf() - new Date(deliveryB.createdAt).valueOf();
  });

  deliveriesCanceled.sort((deliveryA, deliveryB) => {
    // sort canceled deliveries by most recently updated
    return new Date(deliveryB.updatedAt).valueOf() - new Date(deliveryA.updatedAt).valueOf();
  });

  return [
    ...deliveriesLateLoaded,
    ...deliveriesWaitingForLoad,
    ...deliveriesInTransit,
    ...deliveriesAtCustomer,
    ...deliveriesScheduled,
    ...deliveriesCanceled,
  ];
};

export const lateLoadedDeliveryExists = (deliveries: ListDelivery[] = []): boolean => {
  return !!deliveries.find((delivery: ListDelivery) => {
    const { statusType } = getStatus(delivery);
    return statusType === StatusType.LATE_LOADED;
  });
};

export const getLateLoadedDeliveries = (deliveries: ListDelivery[] = []): ListDelivery[] => {
  return deliveries.filter((delivery: ListDelivery) => {
    const { statusType } = getStatus(delivery);
    return statusType === StatusType.LATE_LOADED;
  });
};

export const populateDeliveriesWithPII = async (
  deliveries: ListDelivery[],
  privacyApi: AxiosInstance
): Promise<ListDelivery[]> => {
  return Promise.all(
    deliveries.map(async (delivery: ListDelivery) => {
      if (delivery.customerId && delivery.customerName === 'REDACTED') {
        try {
          const PII = await getPII(privacyApi, delivery.customerId);
          if (!!PII?.name) {
            return {
              ...delivery,
              customerName: PII.name,
            };
          }
        } catch (err) {
          console.error(err);
        }
      }
      return delivery;
    })
  );
};

export const cancelableAttemptStatuses: AttemptStatus[] = [
  AttemptStatus.Requested,
  AttemptStatus.Pending,
  AttemptStatus.RequestFailed,
  AttemptStatus.Scheduled,
  AttemptStatus.Assigned,
  AttemptStatus.AtPickup,
];

import { useAuth0 } from '@auth0/auth0-react';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Collapse } from '@mui/material';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AttemptStatus, Provider, Source } from '@coco/types/deliveries';
import { Colors } from '@coco/ui-web';

import { RobotSimple } from 'src/assets';
import { LOC_NS, ORDERS_KEYS } from 'src/i18n/types';
import { trackSegment } from 'src/lib/segment';
import { FIFTEEN_SECONDS_IN_MS, FIVE_SECONDS_IN_MS, TEN_SECONDS_IN_MS, THIRTY_SECONDS_IN_MS } from 'src/lib/time';
import {
  AttemptProvider,
  DeliveryOperationalMode,
  EVENTS,
  ListDelivery,
  StatusType,
  TrackerDeliveryInfo,
  Trip,
  TripStatus,
} from '../../@types';
import { useDeliveryInfo, useDeliveryV3, useTrips } from '../../hooks';
import { getRobotArrivesIn, getStatus } from '../../lib/deliveries';
import {
  getOrderSourceImageUrl,
  getOrderSourceNameFromSource,
  getProviderImageUrl,
  hasBotSerial,
} from '../../lib/utils';
import Loader from '../Loader';
import Pill from '../shared/Pill';
import DeliveryDetails from './DeliveryDetails';

interface Props {
  delivery: ListDelivery;
  open: boolean;
  onToggle: () => void;
  showPartner: boolean;
  setSuppressLateOrderIds: Dispatch<SetStateAction<string[]>>;
  hasAvailableRobots: boolean;
  isActiveOrdersTab?: boolean;
  useMerchantDashboardNewFeatures?: boolean;
}

const animations = {
  strobe: keyframes({
    '0%': {
      backgroundColor: '#ffa19f',
    },
    '50%': {
      backgroundColor: '#FF6F6B',
    },
    '100%': {
      backgroundColor: '#ffa19f',
    },
  }),
};

const classes = {
  header: css({
    display: 'flex',
    cursor: 'pointer',
    borderWidth: '1px',
    borderRadius: '10px',
    padding: '12px',
    backgroundColor: 'white',
  }),
  lateLoaded: css({
    animation: `${animations.strobe} 1.5s infinite`,
    border: `2px solid #ffa19f`,
  }),
  inTransit: css({
    backgroundColor: '#FFEAB3',
    border: `2px solid #FFD35D`,
  }),
  atDestination: css({
    backgroundColor: '#D2FFE4',
    border: '2px solid #6FE39F',
  }),
  canceled: css({
    backgroundColor: 'white',
    border: 'none',
    opacity: 0.5,
  }),
};

const Container = styled.div`
  background-color: ${Colors.white};
  border-width: 1px;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const ProviderImage = styled.img`
  height: 70px;
`;

const CustomerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
`;

const CustomerName = styled.div`
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const PillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  & > div {
    margin-right: 10px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    & > div {
      margin-bottom: 10px;
    }
  }
`;

const LoadContainer = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: 600px) {
    display: none;
  }
`;

const AttemptStatusContainer = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  text-align: right;
  margin-bottom: 5px;
`;

const TimeSinceAttemptStatus = styled.div`
  padding: 7px 10px 5px;
  font-size: 15px;
  font-weight: 500;
  background-color: rgb(0 0 0 / 20%);
  border-radius: 3px;
  max-width: fit-content;
  white-space: nowrap;
`;

const CollapseContainer = styled.div`
  padding: 0px 20px;
`;

const DeliveryInfoError = styled.div`
  font-size: 15px;
  font-weight: 500;
  text-align: center;
`;

const DeliveryCard = ({
  delivery,
  open,
  onToggle,
  showPartner,
  setSuppressLateOrderIds,
  hasAvailableRobots,
  isActiveOrdersTab = false,
  useMerchantDashboardNewFeatures = false,
}: Props) => {
  const { t, i18n } = useTranslation(LOC_NS.ORDERS);
  const { user } = useAuth0();
  const [robotArrivesInCopy, setRobotArrivesInCopy] = useState<string>('');

  const {
    id: deliveryId,
    currentAttemptStatus,
    orderSource,
    deliveryMedium,
    operationalMode,
    providedBy,
    customerName,
    partnerName,
  } = delivery;

  const deliveryInfoQuery = useDeliveryInfo({
    deliveryId,
    config: {
      enabled: !!deliveryId,
      refetchIntervalInBackground: isActiveOrdersTab,
      refetchInterval: isActiveOrdersTab ? TEN_SECONDS_IN_MS : false,
      refetchOnWindowFocus: false,
    },
  });

  const deliveryInfo = useMemo(() => {
    return deliveryInfoQuery.data as TrackerDeliveryInfo;
  }, [deliveryInfoQuery.data]);

  const deliveryTripId = useMemo(() => {
    return deliveryInfo?.activeAttemptId || '';
  }, [deliveryInfo?.activeAttemptId]);

  const tripInfoQuery = useTrips({
    attemptId: deliveryTripId,
    config: {
      enabled: isActiveOrdersTab && !!deliveryTripId,
      refetchInterval: FIVE_SECONDS_IN_MS,
    },
  });

  const trips = useMemo(() => {
    return (tripInfoQuery.data || []) as Trip[];
  }, [tripInfoQuery.data]);

  const currentTrip = useMemo(() => {
    let currentTripIdx = 0;

    for (let i = 0; i < trips.length; i += 1) {
      const trip = trips[i] as Trip;
      if ([TripStatus.COMPLETED, TripStatus.CANCELED].includes(trip.status)) {
        currentTripIdx += 1;
      } else {
        break;
      }
    }

    return trips[currentTripIdx] as Trip;
  }, [trips]);

  const isUEMarketplaceOrder = useMemo(() => orderSource === Source.uber_eats_marketplace, [orderSource]);

  const designatedRobot = useMemo(() => {
    if (isUEMarketplaceOrder && deliveryMedium) {
      return hasBotSerial(deliveryMedium) ? deliveryMedium?.botSerial : undefined;
    }
  }, [deliveryMedium, isUEMarketplaceOrder]);

  const deliveryCanceled = useMemo(
    () => operationalMode === DeliveryOperationalMode.Canceled || currentAttemptStatus === AttemptStatus.Canceled,
    [currentAttemptStatus, operationalMode]
  );

  const robotAtPickup = useMemo(
    () => providedBy === Provider.Robot && currentAttemptStatus === AttemptStatus.AtPickup,
    [providedBy, currentAttemptStatus]
  );

  const showRobotArrivesIn = useMemo(() => {
    if (
      isActiveOrdersTab &&
      providedBy === Provider.Robot &&
      !!currentAttemptStatus &&
      ([AttemptStatus.Requested, AttemptStatus.Pending] as AttemptStatus[]).includes(currentAttemptStatus)
    ) {
      return isUEMarketplaceOrder ? true : !hasAvailableRobots;
    }
    return false;
  }, [isActiveOrdersTab, providedBy, currentAttemptStatus, isUEMarketplaceOrder, hasAvailableRobots]);

  // need this for estPickupTime until we can safely add it to ListDelivery
  const { data: deliveryV3 } = useDeliveryV3({
    deliveryId,
    config: {
      enabled: showRobotArrivesIn,
      refetchInterval: FIFTEEN_SECONDS_IN_MS,
    },
  });

  useEffect(() => {
    const updateRobotArrivalCopy = () => {
      !!deliveryV3?.attempt && setRobotArrivesInCopy(getRobotArrivesIn(deliveryV3?.attempt));
    };
    const interval = setInterval(updateRobotArrivalCopy, THIRTY_SECONDS_IN_MS);

    updateRobotArrivalCopy();
    return () => clearInterval(interval);
  }, [deliveryV3, i18n.resolvedLanguage]);

  useEffect(() => {
    if (isUEMarketplaceOrder && !robotAtPickup) {
      setSuppressLateOrderIds((prev) => [...prev, deliveryId]);
    } else {
      setSuppressLateOrderIds((prev) => prev.filter((id) => id !== deliveryId));
    }
  }, [deliveryId, isUEMarketplaceOrder, robotAtPickup, setSuppressLateOrderIds]);

  const handleToggle = useCallback(() => {
    if (!open) {
      // only fire event when card is expanded
      trackSegment(EVENTS.BUTTON_CLICKED, {
        button_name: 'Order Card',
        view: isActiveOrdersTab ? 'Active Orders' : 'Order History',
        order_id: deliveryId,
        attempt_status: currentAttemptStatus,
        delivery_provider: providedBy,
        auth_id: user?.sub,
        button_clicked_at: Date.now(),
      });
    }

    onToggle();
  }, [currentAttemptStatus, deliveryId, providedBy, isActiveOrdersTab, onToggle, open, user?.sub]);

  const { statusCopy, timeCopy, statusType } = getStatus(delivery, currentTrip);
  const showLateLoadedStyles = useMemo(() => {
    return statusType === StatusType.LATE_LOADED && isActiveOrdersTab && !showRobotArrivesIn;
  }, [statusType, isActiveOrdersTab, showRobotArrivesIn]);

  const headerClasses = [
    classes.header,
    showLateLoadedStyles && classes.lateLoaded,
    useMerchantDashboardNewFeatures &&
      !!currentAttemptStatus &&
      (
        [AttemptStatus.LoadedWaitingForPilot, AttemptStatus.LoadedWithPilot, AttemptStatus.InTransit] as AttemptStatus[]
      ).includes(currentAttemptStatus) &&
      classes.inTransit,
    useMerchantDashboardNewFeatures &&
      !!currentAttemptStatus &&
      ([AttemptStatus.AtDestination, AttemptStatus.DeliveryInProgress] as AttemptStatus[]).includes(
        currentAttemptStatus
      ) &&
      classes.atDestination,
    useMerchantDashboardNewFeatures && deliveryCanceled && classes.canceled,
  ];

  return (
    <Container>
      <div css={headerClasses} onClick={handleToggle}>
        <ProviderImage src={getProviderImageUrl(providedBy ?? AttemptProvider.ROBOT)} />

        <CustomerContainer>
          <CustomerName>{customerName ?? t(ORDERS_KEYS.CUSTOMER, 'Customer')}</CustomerName>
          <PillsContainer>
            {isActiveOrdersTab && (
              <Pill iconUrl={getOrderSourceImageUrl(orderSource)}>{getOrderSourceNameFromSource(orderSource)}</Pill>
            )}
            {!!designatedRobot && <Pill>{designatedRobot}</Pill>}
            {showRobotArrivesIn && <Pill iconUrl={RobotSimple.src}>{robotArrivesInCopy}</Pill>}
            {showPartner && <Pill>{partnerName ?? t(ORDERS_KEYS.MERCHANT, 'Merchant')}</Pill>}
          </PillsContainer>
        </CustomerContainer>

        <LoadContainer>
          <AttemptStatusContainer>{statusCopy}</AttemptStatusContainer>
          {timeCopy && <TimeSinceAttemptStatus>{timeCopy}</TimeSinceAttemptStatus>}
        </LoadContainer>
      </div>

      <CollapseContainer>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {deliveryInfoQuery.isLoading ? (
            <Loader />
          ) : deliveryInfoQuery.error ? (
            <DeliveryInfoError>
              {t(ORDERS_KEYS.UNABLE_TO_FETCH_ORDER_DETAILS, 'Unable to fetch order details')}
            </DeliveryInfoError>
          ) : (
            <DeliveryDetails listDelivery={delivery} isActiveOrdersTab={isActiveOrdersTab} currentTrip={currentTrip} />
          )}
        </Collapse>
      </CollapseContainer>
    </Container>
  );
};

export default DeliveryCard;

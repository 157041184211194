export enum ERROR {
  QUERY_FN_ERROR = 'Error encountered in query function',
  MUTATION_FN_ERROR = 'Error encountered in mutation function',
  MISSING_ESTIMATED_PICKUP_TIME = 'Missing estimated pickup time',
  PAST_ESTIMATED_PICKUP_TIME = 'Past estimated pickup time',
}

export enum FS_EVENT {
  LANGUAGE_CHANGE = 'Language Change',
}
